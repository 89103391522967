<template>
  <div class="ticket">
    <Spinner :spin="isLoading" />
    <!-- Header -->
    <section class="head">
      <div class="left">
        <a @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span>優惠券</span>
      <div class="right"></div>
    </section>
    <section class="foot">
      <button
        v-if="userInfo.phoneVerified === 0"
        @click.prevent="showNoticePop"
      >
        <span>未驗證會員</span>
      </button>
      <button
        v-else-if="ticket && ticket.status === 'pickedup' && !isRedeemed"
        @click.prevent="showBarcodePop"
      >
        <span>現場兌換</span>
      </button>
      <button v-else class="secondary" @click.prevent="goTo('/tickets')">
        <span>返回兌換紀錄</span>
      </button>
    </section>
    <section class="main" v-if="ticket">
      <!-- 票券 -->
      <div class="coupon">
        <div class="dot left"></div>
        <div class="dot right"></div>
        <div class="label" v-if="ticket.status === 'redeemed'">
          <span>已兌換</span>
        </div>
        <div class="label" v-else-if="ticket.status === 'expired'">
          <span>已過期</span>
        </div>
        <img v-if="ticket.images[0]" :src="ticket.images[0]" />
        <div>
          <span class="title">{{ ticket.title }}</span>
          <span>{{ ticket.storeName }}</span>
          <span v-if="ticket.duration"> {{ ticket.duration }}</span>
          <span v-else>
            {{ tsToDate(ticket.useStartAt) }} -
            {{ tsToDate(ticket.useExpirationAt) }}
          </span>
        </div>
        <hr />
        <div>
          <span class="subtitle">優惠說明</span>
          <span class="content" v-html="ticket.description"></span>
          <span v-if="ticket.restriction" class="subtitle">注意事項</span>
          <span
            v-if="ticket.restriction"
            class="content"
            v-html="ticket.restriction"
          ></span>
        </div>
      </div>
    </section>
    <!-- 優惠券條碼popup -->
    <section
      v-if="ticket"
      id="popBarcode"
      class="pop barcode v2"
      @click="hideBarcodePop"
    >
      <div class="popBody" @click.stop>
        <div class="header">
          <div><img v-if="ticket.images[0]" :src="ticket.images[0]" /></div>
          <span class="title">{{ ticket.title }}</span>
        </div>
        <div class="code">
          <span>請將兌換條碼出示給現場人員</span>
          <barcode
            class="barcode"
            :value="ticket.barcodeMessage"
            format="code128"
            width="2"
          >
            {{ ticket.barcodeMessage }}
          </barcode>
        </div>
      </div>
    </section>
    <!-- 錯誤popup -->
    <section id="popError" class="pop notice" @click.prevent="hideErrorPop">
      <div class="popBody" @click.stop>
        <div>
          <div class="icon">
            <font-awesome-icon
              icon="fa-regular fa-circle-xmark"
              size="5x"
            ></font-awesome-icon>
          </div>
          <span class="title">{{ errorPopContent }}</span>
        </div>
        <div class="buttons">
          <button @click.prevent="hideErrorPop">
            <span>確認</span>
          </button>
        </div>
      </div>
    </section>

    <!-- 未驗證popup -->
    <section
      id="popNotice"
      class="pop notice"
      @click.prevent="hideNoticePop"
      ref="notice"
    >
      <div class="popBody" @click.stop>
        <div>
          <div class="icon">
            <div class="orange">
              <font-awesome-icon
                icon="fa-solid fa-exclamation"
                size="3x"
              ></font-awesome-icon>
            </div>
          </div>
          <span class="title">未驗證會員</span>
          <span>前往驗證會員，享有完整服務</span>
        </div>
        <div class="buttons">
          <button @click.prevent="hideNoticePop">
            <span>取消</span>
          </button>
          <button @click.prevent="goRegistration">
            <span>前往驗證</span>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';
import VueBarcode from 'vue-barcode';
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'Ticket',
  components: {
    barcode: VueBarcode,
    Spinner,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      ticketId: this.$route.params.ticketId,
      ticket: null,
      isLoading: true,
      isRedeemed: false,
      errorPopContent: '',
    };
  },
  beforeRouteEnter(to, from, next) {
    to.meta.from = from;
    next();
  },
  mounted() {
    this.getTicket();
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    ...mapState('portal', ['portalInfo']),
    merchantId() {
      return this.user.user?._json?.merchantId || this.userInfo.merchantId;
    },
  },
  methods: {
    tsToDatetime(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD HH:mm');
    },
    tsToDate(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD');
    },
    callGetTicketAPI() {
      let url = `${this.apiHost}/tickets/v1/merchants/${this.merchantId}/members/${this.user.userId}/tickets/${this.ticketId}`;
      var config = {
        method: 'get',
        url: url,
        headers: {},
      };
      return this.$http(config);
    },
    getTicket() {
      this.callGetTicketAPI()
        .then(res => {
          console.log('getTicket res data: ', res.data);
          this.ticket = res.data;
          this.isLoading = false;
        })
        .catch(err => {
          this.isLoading = false;
          console.log(err);
          this.goTo('/error');
        });
    },
    animatePopClose(el) {
      el.children[0].style.animationName = 'popdown';
      setTimeout(() => {
        el.style.display = 'none';
        el.children[0].style.animationName = 'popup';
      }, 500);
    },
    showBarcodePop() {
      popBarcode.style.display = 'flex';
    },
    hideBarcodePop() {
      this.animatePopClose(popBarcode);
    },
    showErrorPop(message) {
      this.errorPopContent = message;
      popError.style.display = 'flex';
    },
    hideErrorPop() {
      this.animatePopClose(popError);
    },
    showNoticePop() {
      popNotice.style.display = 'flex';
    },
    hideNoticePop() {
      this.animatePopClose(popNotice);
    },
    goTo(path) {
      this.$router.push({ path, query: this.$route.query });
    },
    goBack() {
      const path = this.$route.meta.from.path;
      if (path === '/home') {
        this.$router.go(-1);
      } else {
        this.goTo(path);
      }
    },
    goRegistration() {
      qcsso
        .init({
          appId: this.portalInfo.sso.appId,
          params: { referralCode: this.$route.query.referralCode || '' },
        })
        .then(() => {
          qcsso.login({
            redirectUrl: window.location,
          });
        });
    },
  },
};
</script>
